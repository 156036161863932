<template>
  <tbody>
    <tr class="text-center">
      <td>{{ $t("labels.customer_monitor") }}</td>
      <td>
        <SelectMultipleWarehouse
          :label="$t('labels.warehouse')"
          :placeholder="$t('labels.warehouse')"
          name="id_warehouses"
          :default-value="['all']"
          @onFilter="onFilterChange"
        />
      </td>
      <td>
        <SelectMultipleCustomerByWarehouse
          :label="$t('labels.customer')"
          :placeholder="$t('labels.customer')"
          :id-warehouses="filters.id_warehouses"
          name="id_customers"
          :default-value="['all']"
          @onFilter="onFilterChange"
        />
      </td>
      <td>
        <DateRangeFilter
          :disabled="isDisabledDateRange"
          :min-date="minDate"
          :label="$t('labels.from_to')"
          :placeholder="$t('labels.from_to')"
          name="dates"
          :default-date="filters.dates"
          @onFilter="onFilterChange"
        />
      </td>
      <td>
        <v-btn color="success" :disabled="isDisabledAction" @click="exportExcel"
          ><v-icon>mdi-download</v-icon></v-btn
        >
      </td>
      <td></td>
    </tr>
  </tbody>
</template>

<script>
// import {httpClient} from "@/libs/http";

import moment from "moment";

export default {
  name: "CustomerMonitor",
  components: {
    SelectMultipleWarehouse: () =>
      import("@/components/administration/SelectMultipleWarehouse"),
    SelectMultipleCustomerByWarehouse: () =>
      import("@/components/administration/SelectMultipleCustomerByWarehouse"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
  },
  data: () => ({
    filters: {
      id_warehouses: [],
      id_customers: [],
      dates: [],
    },
    minDate: null,
    isLoading: false,
  }),
  computed: {
    isDisabledDateRange() {
      return (
        !this.filters.id_customers || this.filters.id_customers.length === 0
      );
    },
    isDisabledAction() {
      return (
        this.isDisabledDateRange ||
        !this.filters.dates ||
        this.filters.dates.length === 0
      );
    },
  },
  created() {
    let startOfSubMonth = "2021-01-01";
    let endOfSubMonth = moment().format("YYYY-MM-DD");
    const { startDate, endDate, minDate } = this.defaultDataDate({
      startDate: startOfSubMonth,
      endDate: endOfSubMonth,
      warehouses: (window.me && window.me.warehouses) || [],
    });
    this.minDate = minDate;
    this.filters = { ...this.filters, dates: [startDate, endDate] };
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    async exportExcel() {
      this.$emit("exportExcel", {
        params: this.filters,
        type: "customer_monitor",
      });
    },
  },
};
</script>

<style scoped></style>
